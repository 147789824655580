import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentc from '../components/ServiceDetailsc/ServiceDetailsContentc'
import RelatedServicesc from '../components/ServiceDetailsc/RelatedServicesc'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Financial Accounting"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContentc />
            <RelatedServicesc />
            <Footer />
        </Layout>
    );
}

export default Details