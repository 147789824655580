import React from 'react'
import ServiceSidebarc from './ServiceSidebarc'
import details1c from '../../assets/images/services/services-details1c.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentc = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1c} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Financial Accounting</span>
                            <h3>About this Service</h3>
                            <p>Companies are dealing with a variety of financial reporting difficulties in the face of the unpredictable COVID-19 pandemic and the impact it's having on businesses of all sizes. These include preparation of forward-looking cash-flow estimates, recoverability and impairment of assets, accounting for financial assets, and going concern analyses. </p>
                            <p>Our Financial Accounting Services plays a key role that allows businesses to keep track of all their financial transactions. It is the process in which companies record and report the pieces of financial data that go in and out of their business operations that allow both company managers and outside investors and analysts to understand the company's health and make informed decisions.</p>
                            <p>Our consultant will build the financial statements that help to know where the company stands currently in terms of profitability. For example, reserves and surplus accounts tell you how much funds are available with the company. This helps you to know whether the company has sufficient funds to expand or diversify. Hence, financial accounting helps in making decisions for the future.</p>
                            <p>Our Financial accounting service will help the businesses to keep track of their operations, also to provide a detailed snapshot of their financial health. By providing financial data through a variety of different analyzed statements including the balance sheet, expenses, and income statement, a company can allow investors and lenders great power in their decision-making.</p>
                            <p>A company exists to make profits. Financial accounting is how the management can monitor the financial performance of the business. This enables them to act to restrict losses, focus on the most profitable areas of operation and divert resources to the areas as required. In other words, financial accounting is how the management can evaluate how a business is performing and take steps to grow it.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Statutory Compliance</li>
                                            <li>Future Projections</li>
                                            <li>Financial Statements</li>
                                            <li>Customer Fulfillment</li>
                                            <li>Financial Health Check</li>
                                            <li>Budget Planning</li>
                                            <li>Decision Making</li>
                                            <li>Monitoring Cash Flow</li>
                                            <li>Improved Payment Cycles</li>
                                            <li>Credit building and reputation</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Our Financial Accounting Service will play a vital role in running your business because it helps you track income and expenditures, ensure statutory compliance, and provide investors, management, and government with quantitative financial information, which can be used in making business decisions.</p>
                            <p>Our Financial Accounting service will generate key financial statements.</p>
                            <li>The income statement provides you with information about the profit and loss.</li>
                            <li>The balance sheet gives you a clear picture of the financial position of your business on a particular date.</li>
                            <li>The cash flow statement is a bridge between the income statement and balance sheet and reports the cash generated and spent during a specific time.</li>
                            <p>It is critical you keep your financial records clean and up to date if you want to keep your business afloat. Here are just a few of the reasons why it is important for your business, big or small!</p>
                            <h3>Application Areas</h3>
                            <p>Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Sales & Marketing Application Domain
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>SAP FI Module</span></li>
                                <li><span>Reporting Tools like BW, BEx, Crystal Reports</span></li>
                                <li><span>Dashboards like BO/BODs</span></li>
                                
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarc />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentc